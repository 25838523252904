import React from "react";
import {Box, Button, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SectionTitle from "../../components/SectionTitle";
import OrderFormSelector from "./components/OrderFormSelector";
import OrderFormItemRow from "./components/OrderFormItemRow";
import useOrderForm from "./hooks/useOrderForm";
import OrderFormRow from "./components/OrderFormRow";
import { generateFont } from "../../themes/defaultTheme";

const OrderForm = () => {
  const { form, count, fields, onSubmit, isLoading, formStatus } =
    useOrderForm();

  return (
    <Box
      id={"OrderForm"}
      sx={{
        paddingBottom: "6%",
      }}
    >
      <SectionTitle>Objednávkový formulár</SectionTitle>

      <OrderFormSelector form={form} count={count} />

      <Box
        sx={{
          maxWidth: "72%",
          margin: "0 auto",
          ["@media (max-width:1000px)"]: {
            maxWidth: "96%",
          },
        }}
      >
        {fields.map((item, index) => (
          <OrderFormItemRow
            key={item.id}
            item={item}
            form={form}
            index={index}
            errors={form.formState.errors?.items?.[index]}
          />
        ))}
      </Box>

      <Box
        sx={{
          maxWidth: "40%",
          margin: "0 auto",
          marginTop: "4%",
          ["@media (max-width:1000px)"]: {
            maxWidth: "60%",
          },
          ["@media (max-width:600px)"]: {
            maxWidth: "96%",
          },
        }}
      >
          <Typography
              sx={{
                  textAlign: "center",
                  marginBottom: "8vh",
                  color: '#f44336',
              }}
          >
              Objednávky odosielame prostredníctvom <b>PACKETA</b>. Prosím, do poznámky uveďte <b>NÁZOV</b> a <b>ADRESU</b> zásielkovne, kam si prajete doručiť objednávku.
          </Typography>

        <OrderFormRow
          key={"name"}
          form={form}
          name={"name"}
          label={"Meno a priezvisko"}
          error={form.formState.errors?.["name"]}
        />

        <OrderFormRow
          key={"address"}
          form={form}
          name={"address"}
          label={"Ulica, Mesto, PSČ"}
          error={form.formState.errors?.["address"]}
        />

        <OrderFormRow
          key={"phone"}
          form={form}
          name={"phone"}
          label={"Telefón"}
          error={form.formState.errors?.["phone"]}
        />

        <OrderFormRow
          key={"email"}
          form={form}
          name={"email"}
          label={"E-mail"}
          error={form.formState.errors?.["email"]}
        />

        <OrderFormRow
          isTextarea
          key={"note"}
          form={form}
          name={"note"}
          label={"Poznámka pre nás"}
        />
      </Box>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          sx={{
            marginTop: "43px",
            marginBottom: "43px",
          }}
          onClick={form.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              sx={{
                color: "primary.contrastText",
              }}
            />
          ) : (
            "ODOSLAŤ"
          )}
        </Button>

        {formStatus ? (
          <Box>
            {formStatus === "success" ? (
              <Box
                id={"OrderFormStatus"}
                sx={{
                  fontFamily: "ProximaNovaRegular",
                  ...generateFont({
                    fontSize: 39,
                    lineHeight: 30,
                    letterSpacing: 0,
                    fontWeight: "bold",
                    minClamp: 14,
                  }),
                }}
              >
                Dopyt úspešne odoslaný
              </Box>
            ) : (
              <Box
                id={"OrderFormStatus"}
                sx={{
                  fontFamily: "ProximaNovaRegular",
                  ...generateFont({
                    fontSize: 39,
                    lineHeight: 30,
                    letterSpacing: 0,
                    fontWeight: "bold",
                    minClamp: 14,
                  }),
                }}
              >
                Objednávku sa nepodarilo odoslať
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderForm;
