import React, { useState } from "react";
import { Box, Grid, Modal, useMediaQuery } from "@mui/material";
import GalleryItem from "./GalleryItem";
import galleryItems from "../../../data/galleryItems";
import CloseIcon from "@mui/icons-material/Close";

const GalleryLayout = () => {
  const [modal, setModal] = useState();

  const closeModal = () => setModal();

  const is800Px = useMediaQuery("(max-width:800px)");

  const is600Px = useMediaQuery("(max-width:600px)");

  const is400Px = useMediaQuery("(max-width:400px)");

  return (
    <Box
      sx={{
        margin: "0 6.45% 4% 6.45%",
        ["@media (max-width:1000px)"]: {
          margin: "0 2% 4% 2%",
        },
      }}
    >
      <Modal open={Boolean(modal)} onClose={closeModal}>
        <Box
          sx={{
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={closeModal}
        >
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#fff",
              fontSize: "30px",
            }}
          />
          <Box
            component={"img"}
            src={modal}
            sx={{
              maxWidth: "90%",
                maxHeight: '90vh'
            }}
          />
        </Box>
      </Modal>

      <Grid container spacing={"2%"}>
        {galleryItems.map((galleryItem) => (
          <Grid
            key={galleryItem.id}
            item
            xs={is400Px ? 6 : is600Px ? 4 : is800Px ? 3 : 2.4}
          >
            <GalleryItem {...galleryItem} setModal={setModal} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GalleryLayout;
